
import Vue from "vue";
import API from "@/api/API";
import store from "@/store";
import { Dictionary } from "vue-router/types/router";
import tablePagination from "@/mixins/table-pagination";
import tableFilters from "@/mixins/table-filters";
import { parseArrayParams } from "@/services/helpers";
import ImageViewer from "@/components/viewers/Image.vue";

import { requiredRule } from "@/services/helpers/validation-rules";

export default Vue.extend({
  name: "work-positions",

  mixins: [tablePagination, tableFilters],

  components: {
    ImageViewer
  },

  data: () => ({
    requiredRule,
    parseArrayParams,
    filter: {} as Dictionary<string | boolean | (string | null)[]>,
    headers: [] as Array<HeadersInterface>,
    items: [] as Array<any>,
    selects: {
      users: []
    } as any,
    editorVariables: [] as Array<string>,
    openedTemplate: null as number | null,
    loading: false as boolean
  }),

  computed: {
    rules() {
      return {
        required: this.requiredRule.bind(this)
      };
    }
  },

  async beforeRouteEnter(to, from, next) {
    try {
      const params = {
        filter: to.query,
        page: {
          number: to.params.page,
          size: 48
        }
      };
      const response = await API.emailTemplates().getData(params);
      const variables = await API.emailTemplates().getVariables();
      const usersList = await API.users().getList();

      next(vm => {
        vm.setFilters(params.filter);
        vm.setServerResponse(response);
        vm.editorVariables = variables;
        vm.selects.users = usersList;
      });
    } catch (e) {
      await store.dispatch("alert/showError", e.message);
    }
  },

  methods: {
    async loadData(): Promise<void> {
      this.loading = true;
      try {
        const response = await this.$API.emailTemplates().getData({
          filter: this.parseArrayParams(this.filter),
          page: this.pagination,
          sort: this.getSortParams()
        });
        const variables = await API.emailTemplates().getVariables();
        const usersList = await API.users().getList();

        this.editorVariables = variables;
        this.selects.users = usersList;
        this.setServerResponse(response);
      } catch (e) {
        await this.$store.dispatch("alert/showError", e.message);
      }
      this.loading = false;
    },
    async deleteRow(id: number): Promise<void> {
      this.loading = true;
      try {
        await this.$API.workPositions().delete(id);
        await this.$store.dispatch(
          "alert/showSuccess",
          this.$t("global_alert.successful_removal")
        );
        await this.loadData();
      } catch (e) {
        await this.$store.dispatch("alert/showError", e.message);
      }
      this.loading = false;
    },
    setServerResponse({ items, headers }: any): void {
      this.headers = [
        ...headers,
        {
          text: "",
          value: "actions",
          width: "100px",
          align: "center",
          sortable: false
        }
      ];
      this.items = items?.data;
      this.totalItems = items.total;
    },
    setFilters(filter: Dictionary<string | (string | null)[]>): void {
      const newFilters = this.getAvailableFilters(filter);

      if (Object.keys(newFilters).length) {
        this.showFilters = true;
      }

      this.filter = newFilters;
      this.changeActiveFilters();
    },
    search(): void {
      if (this.debounced) {
        this.debounced.cancel();
      }
      this.debounced = this.debounce(500, this.applyFilters);
      this.debounced();
    }
  }
});
